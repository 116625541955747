export const atenei = [
    { 
        ateneo: 'San Raffaele',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '21',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/',
    },
    {
        ateneo: 'Unipegaso',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '66',
        recensioni: '5.620' ,
        punteggio: '4,5/5',
        img: '/Logo-ECP-Pegaso-2022.png',
    },
    {
        ateneo: "Mercatorum",
        online: 'si',
        cfu: 'si',
        numeroCorsi: '40',
        recensioni: '1512' ,
        punteggio: '4,5/5',
        img: '/universita_mercatorum-1-1024x544.png',
    },
    {
        ateneo: 'eCampus',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '61',
        recensioni: '3552' ,
        punteggio: '4/5',
        img: '/IUL logo 1.png',
    },
    {
        ateneo: 'Uninettuno',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '30',
        recensioni: '63' ,
        punteggio: '4,2/5',
        img: '/uninettuno 1.png',
    },
    {
        ateneo: 'Unimarconi',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '41',
        recensioni: '144' ,
        punteggio: '4/5',
        img: '/IUL logo 1.png',
    },
    {
        ateneo: 'Unitelma',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '21',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/IUL logo 1.png',
    },
    {
        ateneo: 'Unifortunato',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '8',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/universita_giustinofortunato-1-1024x544.png',
    },
    {
        ateneo: 'Unicusano',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '7',
        recensioni: '387' ,
        punteggio: '3,1/5',
        img: '/universita_cusano-1-1024x544.png',
    },
    {
        ateneo: 'Iul',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '30',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/IUL logo 1.png',
    },
    {
        ateneo: 'Unidav',
        online: 'si',
        cfu: 'si',
        numeroCorsi: '3',
        recensioni: '0' ,
        punteggio: '0/5',
        img: '/Unidav 1.png',
    }
]

export const ateneiTech = [
    { 
        ateneo: 'MUSA FORMAZIONE',
        online: 'si',
        recensioni: '60' ,
        punteggio: '4,7/5',
    },
    {
        ateneo: 'AULAB',
        online: 'si',
        recensioni: '254' ,
        punteggio: '4,4/5',
    },
    {
        ateneo: "EPICODE",
        online: 'si',
        recensioni: '153' ,
        punteggio: '4,6/5',
    },
    {
        ateneo: 'START2IMPACT',
        online: 'si',
        recensioni: '250' ,
        punteggio: '4,7/5',
    },
    {
        ateneo: 'Executy',
        online: 'si',
        recensioni: '0' ,
        punteggio: '0/5',
    },
    {
        ateneo: 'TTF',
        online: 'si',
        recensioni: '0' ,
        punteggio: '0/5',
    },
    {
        ateneo: 'DIGITAZON',
        online: 'si',
        recensioni: '69' ,
        punteggio: '4,5/5',
    }
]