import React, { useContext } from 'react'
import logo from '../imgs/LOGO_COMPARA_CORSI_COLOR-2048x289.webp';
import './header.css';
import { isAuth } from '../context/functions';
import { UserContext } from '../context/UserProvider';
import {useNavigate} from 'react-router-dom';
import { useSearch } from '../context/SearchContext';

const Header = () => {
  const [state, setState] = useContext(UserContext);
  const {popupLogin, setPopupLogin} = useSearch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('auth');
    navigate('/universita');
  }
  return (
    <div className='header'>
        <img src={logo} alt='logo comparacorsi' />
        {isAuth() && state ? (
          <div>
            {/*<p>Account</p>*/}
            <p onClick={logout}>Logout</p>
          </div>
        ) : (
          <div onClick={() => setPopupLogin(true)}>
            <p>Accedi</p>
          </div>  
        )}
    </div>
  )
}

export default Header