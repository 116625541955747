
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import ComparatoreLeadSystem from './pages/ComparatoreLeadSystem';
import Header from './components/Header';
import Risultati from './pages/Risultati';
import Footer from './components/Footer';
import Home from './pages/Home';
import ComparatoreTech from './pages/ComparatoreTech';
import RisultatiTech from './pages/RisultatiTech';
import AuthRoute from './context/AuthRoute';
import { isAuth } from './context/functions';
import { useContext } from 'react';
import { UserContext } from './context/UserProvider';

function App() {
  const [state, setState] = useContext(UserContext);
  return (
    <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/universita" element={<ComparatoreLeadSystem />} />
          {isAuth() && state ? (
            <>
            <Route
            path="/universita/risultati"
            element={<Risultati />}
            />   
            <Route
            path="/digital-tech/risultati"
            element={<RisultatiTech />}
            />     
            </>                
          ) : (
            <>
            <Route
            path="/universita/risultati"
            element={<ComparatoreLeadSystem />}
            />   
            <Route
            path="/digital-tech/risultati"
            element={<ComparatoreTech />}
            />
            </>
          )}
          <Route path="/digital-tech" element={<ComparatoreTech />} />
        </Routes>
      <Footer />  
    </Router>
  );
}

export default App;
