import React, {useContext, useEffect, useState} from 'react'
import './auth.css';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import { UserContext } from '../context/UserProvider';
import { useSearch } from '../context/SearchContext';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const Auth = ({setLoginPopup, handleSubmit}) => {
    const {lastName, setLastName, firstName, setFirstName, email, setEmail, phone, setPhone} = useSearch();
    const navigate = useNavigate();

    const [isLoad, setIsLoad] = useState(false);
    const [acceptTreatment, setAcceptTreatment] = useState(false);
    const [state, setState] = useContext(UserContext);
    const [accept, setAccept] = useState(false);
    const [logReg, setLogReg] = useState("login");
    const [password, setPassword] = useState("");
    const [verifyPhone, setVerifyPhone] = useState(false);
    const [code, setCode] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(false);

    const handleChangeOtp = (element, index) => {
        if (isNaN(element.value)) return false;

        setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleChange = (e, setter) => {
        setter(e.target.value);
      };

    const handleLogin = async (e) => {
            try {
              e.preventDefault();
              const { data } = await axios.post("/login", {
                email,
                password,
              });
        
              if (data.error) {
                console.error('Email o Password errata');
              } else {
                setState(data);
                localStorage.setItem("auth", JSON.stringify(data));
                //navigate("/universita/risultati");
                handleSubmit()
              }
            } catch (err) {
              console.log(err);
            }
    }  

    const handleRegister = async (e) => {
        setIsLoad(true);
            if (firstName !== "" || lastName !== "" || email !== "" || phone !== "" || password.length > 6 || accept !== false || firstName.length > 2 || lastName.length > 2) {
              try {
                e.preventDefault();
                const { data } = await axios.post("/register", {
                  name : firstName,
                  surname: lastName,
                  email,
                  phone,
                  password,
                  isChecked: accept,
                });
                console.log(data);
          
                if (data.error) {
                  console.error(data.error);
                } else {
                  localStorage.setItem("auth", JSON.stringify(data));
                  setVerifyPhone(true);
                  setIsLoad(false);
                }
              } catch (err) {
                console.log(err);
              }
            } else if (accept == false) {
              window.alert('Accetta i termini!');
              return;
            } else if (firstName < 3 || lastName < 3 ) {
              window.alert('Inserisci un nome valido!')
              return;
            } else {
              window.alert('Compila tutti i campi e inserisci una password con più di 6 caratteri!');
              return
            };     
    };

    const handleVerifyPhone = async () => {
        const codeString = code.join(""); 
        console.log(codeString);
            try {
              const response = await axios.post('/verify-email', {
                phone: phone,
                code: codeString,
              });
          
              const user = response.data.user;
              const existingToken = JSON.parse(localStorage.getItem('auth')).token;
        
              const updatedAuth = {
                token: existingToken,
                user: user
              };
              setState(updatedAuth);
              localStorage.setItem('auth', JSON.stringify(updatedAuth));
              //navigate('/universita/risultati');
              handleSubmit();
            } catch (error) {
              console.error(error.response.data.error); 
            }
    };

    const handleResendCode = async () => {
      try {
        const response = await axios.post('/resend-code-number', {
          phone: phone,
        });
  
        console.log(response);
        setTimer(true);
        setMinutes(2);
        setSeconds(0);

      } catch (error) {
        console.log(error);
      }
    };

    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          clearInterval(interval);
          setTimer(false);
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }, [minutes, seconds]);

  return (
    <div className='auth-container'>
        {verifyPhone == true ? (
            <>
            <div className='auth-popup-top'>
                <h2>Verifica i tuoi dati</h2>
             </div>
                <div className='comparatore-domande' style={{padding: '0px 0 20px 0'}}>
                  <h2 className='disclaimer'>Abbiamo inviato il codice al telefono {state?.user?.phone}</h2>
                 <p>Inserisci il codice per procedere</p>
                    <div className='domanda' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        {code.map((data, index) => {
                        return (
                            <input
                                className="otp-field"
                                type="text"
                                name="otp"
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={e => handleChangeOtp(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                        );
                    })}
                    </div>
                </div>
                {timer ? <a className='choose'>SMS inviato</a> : <a className='choose' onClick={handleResendCode}>Non hai ricevuto il codice? clicca qui per riceverlo</a>}
                {timer && (
                  <div>
                    <p className='timer'>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</p>
                  </div>
                )}
                {!isLoad ? 
                <button onClick={handleVerifyPhone} className='button-prosegui'>VERIFICA <FaArrowRight /></button> 
                : 
                <button className='button-prosegui'>VERIFICA IN CORSO..</button>
                }
            </>
        ) : (
          <>
                <div className='auth-popup-top'>
                    <FaTimes onClick={() => setLoginPopup(false)} style={{position: 'absolute', left: '10px', top: '20px', cursor: 'pointer'}} />
                    <h2>{logReg == 'login' ? 'Accedi per scoprire i risultati' : 'Completa la registrazione e scopri i risultati'}</h2>
                </div>
                {logReg == 'login' ? (
                <div className='comparatore-domande' style={{padding: '0px 0 20px 0'}}>
                    <a className='choose' onClick={() => setLogReg('registrati')}>Non hai ancora un account? Registati</a>
                    <div className='domanda domanda-input'>
                        <label>Email</label>
                        <input
                        required
                        className={`${email !== "" ? 'filled' : ''}`}
                        type="email"
                        value={email}
                        onChange={(e) => handleChange(e, setEmail)}
                        />
                    </div>
                    <div className='domanda domanda-input'>
                        <label>Password</label>
                        <input
                        required
                        className={`${password !== "" ? 'filled' : ''}`}
                        type="password"
                        value={password}
                        onChange={(e) => handleChange(e, setPassword)}
                        />
                    </div>
                </div>
                ) : (
                <div className='comparatore-domande' style={{padding: '0px 0 20px 0'}}>
                  <h2 className='disclaimer'>Riceverai un codice di verifica via SMS</h2>
                    <a className='choose' onClick={() => setLogReg('login')}>Hai già un account? Accedi</a>
                    <div className='domanda domanda-input'>
                        <label>Nome</label>
                        <input
                        required
                        className={`${firstName !== "" ? 'filled' : ''}`}
                        type="text"
                        value={firstName}
                        onChange={(e) => handleChange(e, setFirstName)}
                        />
                    </div>
                    <div className='domanda domanda-input'>
                        <label>Cognome</label>
                        <input
                        required
                        className={`${lastName !== "" ? 'filled' : ''}`}
                        type="text"
                        value={lastName}
                        onChange={(e) => handleChange(e, setLastName)}
                        />
                    </div>
                    <div className='domanda domanda-input'>
                        <label>Email</label>
                        <input
                        required
                        className={`${email !== "" ? 'filled' : ''}`}
                        type="email"
                        value={email}
                        onChange={(e) => handleChange(e, setEmail)}
                        />
                    </div>
                    <div className='domanda domanda-input'>
                        <label>Telefono</label>
                        <input
                        required
                        className={`${phone !== "" ? 'filled' : ''}`}
                        type="tel"
                        value={phone}
                        onChange={(e) => handleChange(e, setPhone)}
                        />
                    </div>
                    <div className='domanda domanda-input'>
                        <label>Password</label>
                        <input
                        required
                        className={`${password !== "" ? 'filled' : ''}`}
                        type="password"
                        value={password}
                        onChange={(e) => handleChange(e, setPassword)}
                        />
                    </div>
                    <div style={{width: '100%'}} className='domanda-checkbox'>
                        <input
                        type='checkbox'
                        required
                        value={accept}
                        onChange={() => setAccept(!accept)}
                        />
                        <label>
                        Accetto la Normativa <a href='https://www.comparacorsi.it/privacy-policy2/'>Privacy</a> e <a href='https://www.iubenda.com/terms-and-conditions/16806170'>Termini e Condizioni</a>.
                        </label>
                    </div>
                    <div className='domanda-checkbox' style={{textAlign: 'left', display: 'flex', justifyContent:'center', gap: '0.4rem', paddingLeft: '12px'}}>
                      <input
                      type='checkbox'
                      required
                      value={acceptTreatment}
                      onChange={() => setAcceptTreatment(!acceptTreatment)}
                      />
                      <label style={{textAlign: 'left'}}>
                      Presto il consenso al trattamento dei miei dati personali per comunicarli e ricevere informazioni promozionali da parte di terzi ai sensi dell’art. 2.3 lett. c) dell’<a href='https://www.comparatore.it/privacy.php'>informativa privacy</a>
                      </label>
                  </div>
                </div>
                )}
                {!isLoad ? 
                logReg == 'login' ? 
                <button onClick={handleLogin} className='button-prosegui'>ACCEDI <FaArrowRight /></button> 
                :
                <button onClick={handleRegister} className='button-prosegui'>COMPLETA LA REGISTRAZIONE <FaArrowRight /></button> 
                : 
                <button className='button-prosegui'>INVIO IN CORSO..</button>
                }          
          </>  
        )}

    </div>
  )
}

export default Auth